import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { RadioButton } from 'primereact/radiobutton';
import { useDropzone } from 'react-dropzone';

export function IntercambioFormAp({ response }) {
    const [previewImage, setPreviewImage] = useState(null);
    const [disabledState, setDisabledState] = useState(false);
    const [intercambioData, setIntercambioData] = useState(1);
    const [elementoDias, setElementoDias] = useState([]);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange: false,
        onSubmit: (formValue) => {
            console.log('formik function');
            console.log(formValue);
        }
    });

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile[0];
        formik.setFieldValue('ImagenAp', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    useEffect(() => {
        putElementsRow(1);
        formik.setFieldValue("BadgeEmpleado", "Badge - Nombre");
    }, []);

    const putElementsRow = (diasCambios) => {
        setElementoDias([]);
        let elementos_dias_detalle = [];
        for (let index = 0; index < diasCambios; index++) {
            elementos_dias_detalle.push(index);
        }

        setElementoDias(elementos_dias_detalle);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid mt-5 mb-4">
                <div className="col-6">
                    <RadioButton
                        inputId="ingredient1"
                        name="intercambioOption"
                        value="Cheese"
                        onChange={(e) => {
                            formik.setFieldValue('intercambioOption', e.value);
                            setIntercambioData(1);
                        }}
                        checked={formik.values.intercambioOption === 'Cheese'}
                    />
                    <label htmlFor="ingredient1" className="ml-2">
                        Intercambio de la misma personas
                    </label>
                </div>
                <div className="col-6">
                    <RadioButton
                        inputId="ingredient2"
                        name="intercambioOption"
                        value="Cheese2"
                        onChange={(e) => {
                            formik.setFieldValue('intercambioOption', e.value);
                            setIntercambioData(2);
                        }}
                        checked={formik.values.intercambioOption === 'Cheese2'}
                    />
                    <label htmlFor="ingredient2" className="ml-2">
                        Intercambio entre dos personas
                    </label>
                </div>
            </div>

            {intercambioData == 2 && (
                <div className="grid mb-3">
                    <div className="col">
                        <div className="grid">
                            <div className="col-6">
                                <span className="p-float-label">
                                    <InputText className="w-full" name="BadgeEmpleado" value={formik.values.BadgeEmpleado} onChange={(e) => formik.setFieldValue('BadgeEmpleado', e.value)} />
                                    <label htmlFor="BadgeEmpleado">Badge - Nombre de Empleado</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="grid mb-3">
                <div className="col">
                    <span className="p-float-label">
                        <InputText className="w-full" name="DiasCambio" value={formik.values.DiasCambio} onBlur={(e) => putElementsRow(parseInt(e.target.value))} onChange={(e) => formik.setFieldValue('DiasCambio', e.value)} />
                        <label htmlFor="DiasCambio">Ingrese la cantidad de dias que intercambiara</label>
                    </span>
                </div>
            </div>

            {elementoDias.map((item, index) => (

                <div className="grid mb-4" key={index}>
                    <div className="col-3">
                        <span className="p-float-label">
                            <Calendar dateFormat="yy-mm-dd" className="w-full" name={`DiaDescanso_${index}`} value={formik.values[`DiaDescanso_${index}`]} onChange={(e) => formik.setFieldValue(`DiaDescanso_${index}`, e.value)} />
                            <label htmlFor={`DiaDescanso_${index}`}>Fecha Efectiva</label>
                        </span>
                    </div>
                    <div className="col-3">
                        <span className="p-float-label">
                            <Calendar dateFormat="yy-mm-dd" className="w-full" name={`DiaCambio_${index}`} value={formik.values[`DiaCambio_${index}`]} onChange={(e) => formik.setFieldValue(`DiaCambio_${index}`, e.value)} />
                            <label htmlFor={`DiaCambio_${index}`}>Dia para Intercambiar</label>
                        </span>
                    </div>
                    <div className="col-3">
                        <span className="p-float-label">
                            <Calendar
                                timeOnly
                                className="w-full"
                                id={`HoraEntrada_${index}`}
                                name={`HoraEntrada_${index}`}
                                value={formik.values[`HoraEntrada_${index}`]}
                                onChange={(e) => {
                                    formik.setFieldValue(`HoraEntrada_${index}`, e.value);
                                }}
                            />
                            <label htmlFor={`HoraEntrada_${index}`}>Hora de Entrada</label>
                        </span>
                    </div>
                    <div className="col-3" key={index}>
                        <span className="p-float-label" key={index}>
                            <Calendar timeOnly key={index} className="w-full" name={`HoraSalida_${index}`} value={formik.values[`HoraSalida_${index}`]} onChange={(e) => formik.setFieldValue(`HoraSalida_${index}`, e.value)} />
                            <label htmlFor={`HoraSalida_${index}`}>Hora de Salida</label>
                        </span>
                    </div>
                </div>

            ))}

            <div className="grid">
                <div className="col-6">
                    <Button type="button" severity="primary" size="large" {...getRootProps()}>
                        Subir Imagen para la AP
                    </Button>
                    <input {...getInputProps()} />
                </div>
                <div className="col-6">
                    <Image src={previewImage} width="100%" />
                </div>
            </div>
            <div className="grid my-5">
                <div className="col">
                    <Button severity="success" type="submit" disabled={disabledState}>
                        Guardar Ap
                    </Button>
                </div>
            </div>
        </form>
    );
}

function initialValues() {
    return {

        DiaDescanso_0: '',
        DiaCambio_0: '',
        HoraEntrada_0: '',
        HoraSalida_0: '',

        DiaDescanso_1: '',
        DiaCambio_1: '',
        HoraEntrada_1: '',
        HoraSalida_1: '',

        DiaDescanso_2: '',
        DiaCambio_2: '',
        HoraEntrada_2: '',
        HoraSalida_2: '',

        DiaDescanso_3: '',
        DiaCambio_3: '',
        HoraEntrada_3: '',
        HoraSalida_3: '',

        DiaDescanso_4: '',
        DiaCambio_4: '',
        HoraEntrada_4: '',
        HoraSalida_4: '',

        DiaDescanso_5: '',
        DiaCambio_5: '',
        HoraEntrada_5: '',
        HoraSalida_5: '',

        DiaDescanso_6: '',
        DiaCambio_6: '',
        HoraEntrada_6: '',
        HoraSalida_6: '',

        intercambioOption: 'Cheese',
        DiasCambio: '1',
        DiaDescansoInter: '',
        BadgeEmpleado: '',
        DiaCambioInter: '',
        HoraEntradaInter: '',
        HoraSalidaInter: '',
        Observacion: '',
        Imagen: ''
    };
}

function validationSchema() {
    return {

        DiaDescanso_0: Yup.string(),
        DiaCambio_0: Yup.string(),
        HoraEntrada_0: Yup.string(),
        HoraSalida_0: Yup.string(),

        DiaDescanso_1: Yup.string(),
        DiaCambio_1: Yup.string(),
        HoraEntrada_1: Yup.string(),
        HoraSalida_1: Yup.string(),

        DiaDescanso_2: Yup.string(),
        DiaCambio_2: Yup.string(),
        HoraEntrada_2: Yup.string(),
        HoraSalida_2: Yup.string(),

        DiaDescanso_3: Yup.string(),
        DiaCambio_3: Yup.string(),
        HoraEntrada_3: Yup.string(),
        HoraSalida_3: Yup.string(),

        DiaDescanso_4: Yup.string(),
        DiaCambio_4: Yup.string(),
        HoraEntrada_4: Yup.string(),
        HoraSalida_4: Yup.string(),

        DiaDescanso_5: Yup.string(),
        DiaCambio_5: Yup.string(),
        HoraEntrada_5: Yup.string(),
        HoraSalida_5: Yup.string(),

        DiaDescanso_6: Yup.string(),
        DiaCambio_6: Yup.string(),
        HoraEntrada_6: Yup.string(),
        HoraSalida_6: Yup.string(),

        intercambioOption: Yup.string(),
        DiaDescansoInter: Yup.string(),
        BadgeEmpleado: Yup.string(),
        DiaCambio: Yup.string(),
        DiaCambioInter: Yup.string(),
        HoraEntradaInter: Yup.string(),
        HoraSalidaInter: Yup.string(),
        Observacion: Yup.string(),
        Imagen: Yup.string()
    };
}
